import React from 'react';
import queryString from 'query-string'

const RulesPSPage = ({ pathContext: { locale }, location }) => {

    const isBrowser = typeof window !== "undefined"

    if (isBrowser) {
        const query = queryString.parse(location.search);

        const prefix = (locale === 'en' ?  '/en/rules_documents_dengi_ru/' : '/rules_documents_dengi_ru/');

        if (query.section){
             window.location.replace(prefix + query.section);
        } else {
             window.location.replace(prefix + 'registration');
        }
    }

    return (
        <>
            redirect...
        </>
    )
};

export default RulesPSPage;
